<template>
  <Page id="policy">
    <template v-slot:content>
      <Section>
        <SectionHeader :title="$t('pages.PolicyAndPrivacy.title')" />
        <div class="banner-container">
          <a href="http://gsci.pt/cretaildealer">
            <img
              :src="
                $t('resources.images.privacypolicypage.hero') ||
                require('../../../assets/images/policy-banner.webp')
              "
              alt=""
            />
          </a>
        </div>
        <div class="policy-container">
          <div v-for="section in sections" :key="section" class="policy-block">
            <Subtitle :label="$t(`pages.PolicyAndPrivacy.${section}.title`)" />
            <TextLabel
              v-html="$t(`pages.PolicyAndPrivacy.${section}.textLabel`)"
            />
          </div>
        </div>
      </Section>
    </template>
  </Page>
</template>

<script>
import { Page } from "../../templates";
import {
  SectionHeader,
  Subtitle,
  TextLabel,
  Section,
} from "@sc/ds-ui-component-library";

export default {
  name: "PolicyPrivacyPage",
  components: {
    Page,
    SectionHeader,
    Subtitle,
    TextLabel,
    Section,
  },
  metaInfo() {
    return {
      title: this.$t("meta.policy.title"),
      meta: [
        { charset: "utf-8" },
        {
          vmid: "description",
          name: "description",
          content: this.$t("meta.policy.description"),
        },
        {
          vmid: "keywords",
          name: "keywords",
          content: this.$t("meta.policy.keywords"),
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: this.$t("meta.policy.title"),
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.$t("meta.policy.description"),
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: window.location.href,
        },
        {
          vmid: "og:image",
          property: "og:image",
          content: this.$t("meta.policy.image"),
        },
      ],
    };
  },
  data() {
    return {
      sections: [
        "section1",
        "section2",
        "section3",
        "section4",
        "section5",
        "section6",
        "section7",
        "section8",
      ],
    };
  },
  mounted() {
    this.$meta().refresh();
  },
};
</script>

<style scoped lang="scss">
#policy {
  --section-header-width: 100%;
  --section-header-text-align: center;
  --section-header-margin: 0 0 64px 0;
  white-space: pre-line;
  --section-bg-color: var(--color-7);
}
.policy-block,
.policy-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: justify;
  white-space: pre-line;
}

.policy-container {
  gap: 32px;
}

.banner-container {
  width: 100%;
  height: 100%;
  margin: 30px 0px;
}

@media (max-width: 1024px) {
  #policy {
    --section-padding: 0px 16px 30px 16px;
    --section-header-width: 100%;
    --section-header-margin: 30px auto;

    ::v-deep {
      .btn {
        width: 100%;
        --button-justify-content: center;
      }
    }
  }
}

@media (max-width: 768px) {
  ::v-deep .title {
    --title-font-size: var(--title-3-font-size);
    --title-line-height: var(--title-3-line-height);
    --title-weight: var(--font-weight-bold);
  }

  #policy {
    --section-header-width: 100%;
  }
}
</style>
